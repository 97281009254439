import { Injectable } from '@angular/core';
import { SFSObject, SFSUser } from 'sfs2x-api';
import { map } from 'rxjs/operators';
import { LocationPage } from '../pages/location/location.page';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  public appPages = [];
  public labels = [
    { tekst: 'Polska Partia Piratów', icon: 'globe-outline', www: 'https://polskapartiapiratow.pl'},
    { tekst: 'FUNpage', icon: 'logo-facebook', www: 'https://www.facebook.com/PolskaPartiaPiratow'},
    { tekst: 'Twitter', icon: 'logo-twitter', www: 'https://twitter.com/PartiaPiratow'},
    { tekst: 'Video', icon: 'logo-youtube', www: 'https://www.youtube.com/channel/UCrLWx6SS6VM71WrxIiqBCig'},
    { tekst: 'Discord', icon: 'logo-discord', www: 'https://discord.gg/uq742ZGE'},
    { tekst: 'KuźniaPiratów', icon: 'logo-github', www: 'https://github.com/PirateForge'},
    { tekst: 'Regulamin', icon: 'newspaper-outline', www: '/assets/regulamin.pdf'}
  ];

  isLimbo = false;
  iamFighting = false;
  chat: {};
  messages = [];

  appReference: AppComponent;

  roomersList = [];
  roomersListPlayers = [];

  myName: string;
  myOwnImage;

  startMoveFromLocation: any;
  showingAccount: SFSUser;
  currentDirections = [];
  currentDirectionsLocations = [];

  locationPage: LocationPage;


  constructor() {
    this.chat = {
      createdBy : 'MYSELF',
    }
  }

  saveAvatar()
  {
    console.log('save to afb')
  }

  getStrength(num, masculin)
  {
    let sila:String;
    if(masculin)
    {
      if(num<10) sila = 'lichy';
      else if(num>=10 && num<20) sila = 'rachityczny';
      else if(num>=20 && num<30) sila = 'słaby';
      else if(num>=30 && num<40) sila = 'krzepki';
      else if(num>=40 && num<50) sila = 'silny';
      else if(num>=50 && num<60) sila = 'silny2';
      else if(num>=60 && num<70) sila = 'mocny';
      else if(num>=70 && num<80) sila = 'mocny2';
      else if(num>=80 && num<90) sila = 'bardzo silny';
      else if(num>=90) sila = 'mocarny';
    }
    else
    {
      if(num<10) sila = 'licha';
      else if(num>=10 && num<20) sila = 'rachityczna';
      else if(num>=20 && num<30) sila = 'słaba';
      else if(num>=30 && num<40) sila = 'krzepka';
      else if(num>=40 && num<50) sila = 'silna';
      else if(num>=50 && num<60) sila = 'silna2';
      else if(num>=60 && num<70) sila = 'mocna';
      else if(num>=70 && num<80) sila = 'mocna2';
      else if(num>=80 && num<90) sila = 'bardzo silna';
      else if(num>=90) sila = 'mocarna';
    }
    

    return sila;
  }

  

  getInitiative(num, masculin)
  {
    let ini:String;

    if(masculin)
    {
      if(num<10) ini = 'ini_10';
      else if(num>=10 && num<20) ini = 'ini_11';
      else if(num>=20 && num<30) ini = 'ini_12';
      else if(num>=30 && num<40) ini = 'ini_13';
      else if(num>=40 && num<50) ini = 'ini_14';
      else if(num>=50 && num<60) ini = 'ini_15';
      else if(num>=60 && num<70) ini = 'ini_16';
      else if(num>=70 && num<80) ini = 'ini_17';
      else if(num>=80 && num<90) ini = 'ini_18';
      else if(num>=90) ini = 'ini_19';
    }
    else
    {
      if(num<10) ini = 'ini_10';
      else if(num>=10 && num<20) ini = 'ini_11';
      else if(num>=20 && num<30) ini = 'ini_12';
      else if(num>=30 && num<40) ini = 'ini_13';
      else if(num>=40 && num<50) ini = 'ini_14';
      else if(num>=50 && num<60) ini = 'ini_15';
      else if(num>=60 && num<70) ini = 'ini_16';
      else if(num>=70 && num<80) ini = 'ini_17';
      else if(num>=80 && num<90) ini = 'ini_18';
      else if(num>=90) ini = 'ini_19';
    }
    
    return ini;
  }

  getDexternity(num, masculin)
  {
    let sila:String;

    if(masculin)
    {
      if(num<10) sila = 'niemrawy';
      else if(num>=10 && num<20) sila = 'ślamazarny';
      else if(num>=20 && num<30) sila = 'zwolniony';
      else if(num>=30 && num<40) sila = 'sprawny';
      else if(num>=40 && num<50) sila = 'gibki';
      else if(num>=50 && num<60) sila = 'szybki';
      else if(num>=60 && num<70) sila = 'zwinny';
      else if(num>=70 && num<80) sila = 'bardzo szybki';
      else if(num>=80 && num<90) sila = 'bardzo zwinny';
      else if(num>=90) sila = 'akrobata';
    }
    else
    {
      if(num<10) sila = 'niemrawa';
      else if(num>=10 && num<20) sila = 'ślamazarna';
      else if(num>=20 && num<30) sila = 'zwolniona';
      else if(num>=30 && num<40) sila = 'sprawna';
      else if(num>=40 && num<50) sila = 'gibka';
      else if(num>=50 && num<60) sila = 'szybka';
      else if(num>=60 && num<70) sila = 'zwinna';
      else if(num>=70 && num<80) sila = 'bardzo szybka';
      else if(num>=80 && num<90) sila = 'bardzo zwinna';
      else if(num>=90) sila = 'akrobatka';
    }
    
    return sila;
  }


  getMyType(type)
  {
    //console.log('getMyType',type);

    //let typeIkon;
    if(type=='human') return 'body';
    else return 'hardware-chip';
    
  }

  addChatMSG(msg)
  {
    console.log('addChatMSG',msg.image);

    if(msg.payload)
    {
      const mapka:any = new Map(Object.entries(msg.payload));
      //console.log('TYPE', mapka._dataHolder);

      mapka.forEach((values:any,keys)=>{ 

        //console.log('keys',keys) 
        if(keys=='_dataHolder')
        {
          
          var mapIter = values.entries();
          let akcja =  mapIter.next().value[1].value;
          console.log('TYPE akcja', akcja);

          if(akcja=="BORED")
          {
            console.log('I AM BORED--------');
            msg.subtype = 'BORED'
          }
          else if(akcja=="DEAD")
          {
            if(msg.userId == this.myName)
            {
              //console.log('Poka LIMBO----', msg);
             
              msg.userId = "Zegarmistrz Światła Purpurowy";

              msg.text = msg.userId + ": Umierasz..."
              msg.image= "./assets/images/enclavia-logo-small.png";
              this.isLimbo = true;
              this.appReference.odpalLimbo();
            }
            else
            {
              console.log('Ktoś padł');
            }
            
          }
          else if(akcja=="LEAVES")
          {
            console.log('LEAVES----------');
            msg.subtype = 'LEAVES'
          }
          else if(akcja=="ENTERS")
          {
            console.log('ENTERS----------');
            msg.subtype = 'ENTERS';
            this.locationPage.updateCounts();
          }

        }

        //console.log('values', values) ;
        
        
      }) 
    }
    this.messages.push(msg)
  }
  
}
