import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'p3',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'roza-wiatrow',
    loadChildren: () => import('./pages/roza-wiatrow/roza-wiatrow.module').then( m => m.RozaWiatrowPageModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./pages/maps/maps.module').then( m => m.MapsPageModule)
  },
  {
    path: 'travel',
    loadChildren: () => import('./pages/travel/travel.module').then( m => m.TravelPageModule)
  },
  {
    path: 'skrzynia',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'accounts-list',
    loadChildren: () => import('./pages/accounts-list/accounts-list.module').then( m => m.AccountsListPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'p3',
    loadChildren: () => import('./pages/kompas/kompas.module').then( m => m.KompasPageModule)
  },
  {
    path: 'profile/:userId',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
      }
    ]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'peek-account',
    loadChildren: () => import('./pages/peek-account/peek-account.module').then( m => m.PeekAccountPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
