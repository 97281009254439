import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  notifications;

  constructor() {
    this.notifications = {
      read: [],
      unread: []
    };
   }
}
