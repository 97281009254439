import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular';
// import { AppComponent } from '../app.component';
import { FirebaseService } from './firebase.service';
import { TimelineService } from './timeline.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static MY_ACCOUNT_ID = '';

  account;
  myId;
  isLoggedIn = false;
  myImageAvatar;
  peekingProfile;

  constructor(private toastController: ToastController,
              private firebaseService: FirebaseService, public afa: AngularFireAuth,
              private afs: AngularFirestore, private timelineService: TimelineService)
  {


    this.afa.authState.subscribe(user => {

        if (user)
        {

          const { uid, email } = user;

          this.account = user;
          // console.log('JEST KOLO', user.uid);

          this.isLoggedIn = true;

          this.myId = user.uid;

          this.timelineService.appPages[1].url = '/profile/' + this.myId;

          // this.app.refresh(user.uid);

          if (!user.email)
          {
            // console.log('BRAK @ maila');
            //  this.account
          }

          if (user.isAnonymous === true)
          {
            console.log('ANON MODE - ON!!!');

          }
        }
        else
        {
          // console.log('BRAK KOLO');
        }

    });
  }

  async saveMyImage(userId, avatarNew) {
    this.afs.doc('accounts/' + userId).update({ avatar: avatarNew });
  }


  getAccount(userId = null) {
    
      // console.log('TAKE userId', userId);

      const currentUser = userId ? userId : AuthService.MY_ACCOUNT_ID;
 
      if (!currentUser || currentUser === 'undefined' || currentUser === '')
      {
        // console.log('NO currentUser');
        return null;
      }

      const doc: any = this.afs.doc('accounts/' + currentUser);
      // console.log('kogo doc', userId, currentUser, doc);

      if (!doc)
      {
        return { null: true };
      }

      return this.account = doc.valueChanges();
    
  }

  async presentRenderWarningOptions() {

    const rnd = Math.random();
    if (rnd > 0.4) { return; }


    const toast = await this.toastController.create({
      header: '[WARNING] - Beta v0.8.4',
      message: 'Jeżeli nie pojawi się mapa, musisz odświeżyć okno przeglądarki',
      position: 'top',
      duration: 10000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('ok clicked');
            // this.router.navigate(['/kompas']);
          }
        }
      ]
    });
    toast.present();
  }

  getKompasData(uid)
  {
    return this.afs.collection('accounts').doc(uid).valueChanges();
  }

  getRecentKompasData()
  {
    return this.afs.collection('accounts', ref => ref.orderBy('createdAt', 'desc')).snapshotChanges();
  }

  deleteKompasData(uid)
  {
    return this.afs.collection('accounts').doc(uid).valueChanges();
  }
  
  public czekAccount()
  {
    console.log('czekAccount', this.account);
  }

}
