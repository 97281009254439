import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocationData {

  globalSFSName:string;
  gpsX:number;
  gpsY:number;

  up:string;
  down:string;

  n:string; 
  ne:string; 
  e:string; 
  se:string; 
  s:string; 
  sw:string; 
  w: string;
  nw:string; 

  constructor(_name:string, _gpX:number, _gpY:number, _n:string, _ne:string, _e:string, _se:string, _s:string, _sw:string, _w:string, _nw:string) { 

    this.globalSFSName = _name;
    this.gpsX = _gpX;
    this.gpsY = _gpY;
    this.n = _n;
    this.ne = _ne;
    this.e = _e;
    this.se = _se;
    this.s = _s;
    this.sw = _sw;
    this.w = _w;
    this.nw = _nw;
  }

}

export class ZoneDataManagerService {

  actualRoomName:string;

  public currentLocation:LocationData;
  locations:Array<LocationData>;

  constructor() {
    //this.initData()
   }
   

   initData()
   {
    this.locations = [];

    let lokL:LocationData = new LocationData("THE_LOBBY", 1981,502, "LOCATION_0", "", "", "LOCATION_8", "", "LOCATION_10", "", "");
    this.locations.push(lokL);

    let lok0:LocationData = new LocationData("LOCATION_0", 1984,490, "LOCATION_1", "", "LOCATION_7", "", "THE_LOBBY", "", "LOCATION_12", "");
    this.locations.push(lok0);

    let lok1:LocationData = new LocationData("LOCATION_1", 1987,471, "LOCATION_2", "", "", "", "LOCATION_0", "", "", "");
    this.locations.push(lok1);

    let lok2:LocationData = new LocationData("LOCATION_2", 1990,454, "", "", "LOCATION_3", "", "LOCATION_1", "", "LOCATION_16", "");
    this.locations.push(lok2);

    let lok3:LocationData = new LocationData("LOCATION_3", 2009,458, "", "", "LOCATION_4", "", "", "", "LOCATION_2", "");
    this.locations.push(lok3);

    let lok4:LocationData = new LocationData("LOCATION_4", 2025,460, "", "", "", "LOCATION_5", "", "LOCATION_74", "LOCATION_3", "");
    this.locations.push(lok4);

    let lok5:LocationData = new LocationData("LOCATION_5", 2037,478, "", "", "", "LOCATION_36", "", "LOCATION_6", "", "LOCATION_4");
    this.locations.push(lok5);

    let lok6:LocationData = new LocationData("LOCATION_6", 2014,488, "LOCATION_74", "LOCATION_5", "", "", "LOCATION_24", "LOCATION_7", "", "");
    this.locations.push(lok6);

    let lok7:LocationData = new LocationData("LOCATION_7", 1994,497, "", "LOCATION_6", "", "", "LOCATION_8", "", "LOCATION_0", "");
    this.locations.push(lok7);

    let lok8:LocationData = new LocationData("LOCATION_8", 1990,510, "LOCATION_7", "", "", "LOCATION_28", "", "LOCATION_9", "", "");
    this.locations.push(lok8);

    let lok9:LocationData = new LocationData("LOCATION_9", 1978,519, "", "LOCATION_8", "", "", "LOCATION_18", "", "LOCATION_11", "");
    this.locations.push(lok9);

    let lok10:LocationData = new LocationData("LOCATION_10", 1970,507, "LOCATION_12", "THE_LOBBY", "LOCATION_8", "", "LOCATION_11", "", "", "");
    this.locations.push(lok10);

    let lok11:LocationData = new LocationData("LOCATION_11", 1964,518, "LOCATION_10", "", "LOCATION_9", "LOCATION_18", "LOCATION_17", "", "LOCATION_29", "");
    this.locations.push(lok11);

    let lok12:LocationData = new LocationData("LOCATION_12", 1972,492, "", "", "LOCATION_0", "", "LOCATION_10", "", "", "LOCATION_13");
    this.locations.push(lok12);

    let lok13:LocationData = new LocationData("LOCATION_13", 1959,476, "", "", "", "LOCATION_12", "", "", "", "LOCATION_14");
    this.locations.push(lok13);

    let lok14:LocationData = new LocationData("LOCATION_14", 1944,460, "LOCATION_15", "", "", "LOCATION_13", "", "", "", "LOCATION_35");
    this.locations.push(lok14);

    let lok15:LocationData = new LocationData("LOCATION_15", 1953,444, "", "", "LOCATION_16", "", "LOCATION_14", "", "LOCATION_35", "");
    this.locations.push(lok15);

    let lok16:LocationData = new LocationData("LOCATION_16", 1974,449, "", "", "LOCATION_2", "", "", "", "LOCATION_15", "");
    this.locations.push(lok16);

    let lok17:LocationData = new LocationData("LOCATION_17", 1961,533, "LOCATION_11", "", "", "", "", "", "", "");
    this.locations.push(lok17);

    let lok18:LocationData = new LocationData("LOCATION_18", 1974,535, "LOCATION_9", "", "", "", "", "LOCATION_19", "", "");
    this.locations.push(lok18);

    let lok19:LocationData = new LocationData("LOCATION_19", 1964,550, "", "LOCATION_18", "", "", "LOCATION_20", "", "", "");
    this.locations.push(lok19);

    
    let lok20:LocationData = new LocationData("LOCATION_20", 1964,576, "LOCATION_19", "", "", "", "LOCATION_21", "", "", "");
    this.locations.push(lok20);

    let lok21:LocationData = new LocationData("LOCATION_21", 1968,594, "LOCATION_20", "", "", "LOCATION_22", "", "", "", "");
    this.locations.push(lok21);

    let lok22:LocationData = new LocationData("LOCATION_22", 1972,605, "", "", "", "", "", "LOCATION_23", "", "LOCATION_21");
    this.locations.push(lok22);

    let lok23:LocationData = new LocationData("LOCATION_23", 1963,625, "", "LOCATION_22", "", "", "", "", "", "");
    this.locations.push(lok23);

    let lok24:LocationData = new LocationData("LOCATION_24", 2011,504, "LOCATION_6", "LOCATION_25", "", "", "LOCATION_28", "", "", "");
    this.locations.push(lok24);

    let lok25:LocationData = new LocationData("LOCATION_25", 2030,497, "", "LOCATION_36", "", "LOCATION_26", "", "LOCATION_24", "", "");
    this.locations.push(lok25);

    let lok26:LocationData = new LocationData("LOCATION_26", 2042,506, "LOCATION_36", "", "", "", "LOCATION_27", "", "", "LOCATION_25");
    this.locations.push(lok26);

    let lok27:LocationData = new LocationData("LOCATION_27", 2039,530, "LOCATION_26", "", "", "", "", "", "LOCATION_28", "");
    this.locations.push(lok27);

    let lok28:LocationData = new LocationData("LOCATION_28", 2011,521, "LOCATION_24", "", "LOCATION_27", "", "", "", "", "LOCATION_8");
    this.locations.push(lok28);

    let lok29:LocationData = new LocationData("LOCATION_29", 1952,510, "", "", "LOCATION_10", "LOCATION_11", "", "", "LOCATION_30", "");
    this.locations.push(lok29);

    let lok30:LocationData = new LocationData("LOCATION_30", 1917,505, "", "", "LOCATION_29", "", "", "", "LOCATION_31", "");
    this.locations.push(lok30);

    let lok31:LocationData = new LocationData("LOCATION_31", 1886,502, "LOCATION_32", "", "LOCATION_30", "", "", "", "LOCATION_37", "");
    this.locations.push(lok31);

    let lok32:LocationData = new LocationData("LOCATION_32", 1889,474, "LOCATION_33", "", "", "", "LOCATION_31", "", "", "");
    this.locations.push(lok32);

    let lok33:LocationData = new LocationData("LOCATION_33", 1895,445, "LOCATION_34", "", "", "", "LOCATION_32", "", "", "");
    this.locations.push(lok33);

    let lok34:LocationData = new LocationData("LOCATION_34", 1909,421, "", "", "", "LOCATION_35", "LOCATION_33", "", "", "");
    this.locations.push(lok34);

    let lok35:LocationData = new LocationData("LOCATION_35", 1931,442, "", "", "LOCATION_15", "LOCATION_14", "", "", "", "LOCATION_34");
    this.locations.push(lok35);

    let lok36:LocationData = new LocationData("LOCATION_36", 2044,488, "", "", "", "", "LOCATION_26", "LOCATION_25", "", "LOCATION_5");
    this.locations.push(lok36);

    let lok37:LocationData = new LocationData("LOCATION_37", 1847,495, "", "", "LOCATION_1", "", "", "", "LOCATION_38", "");
    this.locations.push(lok37);

    let lok38:LocationData = new LocationData("LOCATION_38", 1800,487, "", "", "LOCATION_37", "", "", "", "LOCATION_39", "");
    this.locations.push(lok38);

    let lok39:LocationData = new LocationData("LOCATION_39", 1749,479, "", "", "LOCATION_38", "", "", "", "LOCATION_40", "");
    this.locations.push(lok39);

    let lok40:LocationData = new LocationData("LOCATION_40", 1705,473, "", "", "LOCATION_39", "", "", "", "", "");
    this.locations.push(lok40);

    /*
    let lok41:LocationData = new LocationData("LOCATION_41", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok42:LocationData = new LocationData("LOCATION_42", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok43:LocationData = new LocationData("LOCATION_43", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok44:LocationData = new LocationData("LOCATION_44", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok45:LocationData = new LocationData("LOCATION_45", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok46:LocationData = new LocationData("LOCATION_46", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok47:LocationData = new LocationData("LOCATION_47", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok48:LocationData = new LocationData("LOCATION_48", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok49:LocationData = new LocationData("LOCATION_49", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok50:LocationData = new LocationData("LOCATION_50", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok51:LocationData = new LocationData("LOCATION_51", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok52:LocationData = new LocationData("LOCATION_52", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok53:LocationData = new LocationData("LOCATION_53", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok54:LocationData = new LocationData("LOCATION_54", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok55:LocationData = new LocationData("LOCATION_55", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok56:LocationData = new LocationData("LOCATION_56", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok57:LocationData = new LocationData("LOCATION_57", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok58:LocationData = new LocationData("LOCATION_58", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok59:LocationData = new LocationData("LOCATION_59", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok60:LocationData = new LocationData("LOCATION_60", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok61:LocationData = new LocationData("LOCATION_61", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok62:LocationData = new LocationData("LOCATION_62", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok63:LocationData = new LocationData("LOCATION_63", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok64:LocationData = new LocationData("LOCATION_64", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok65:LocationData = new LocationData("LOCATION_65", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok66:LocationData = new LocationData("LOCATION_66", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok67:LocationData = new LocationData("LOCATION_67", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok68:LocationData = new LocationData("LOCATION_68", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok69:LocationData = new LocationData("LOCATION_69", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok70:LocationData = new LocationData("LOCATION_70", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok71:LocationData = new LocationData("LOCATION_71", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok72:LocationData = new LocationData("LOCATION_72", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);

    let lok73:LocationData = new LocationData("LOCATION_73", 1984,490, "", "", "", "", "", "", "", "");
    this.locations.push(lok2);


    */

   let lok74:LocationData = new LocationData("LOCATION_74", 2008,475, "", "LOCATION_4", "", "", "LOCATION_6", "", "", "");
   this.locations.push(lok74);

    console.log('locations num', this.locations.length)
   }


   getLocationByID(id:string):LocationData
   {
    //console.log('ile mam lokacji?', this.locations)
    let foundLoca = null;
         
     this.locations.forEach(element => {
      //console.log('what czek?', element)
       if(element.globalSFSName==id) 
       {
         console.log('found', element)
         foundLoca = element;
       }
     });

     return foundLoca;
   }
}
