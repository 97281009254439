import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as SFS2X from 'sfs2x-api';
import { MsgTypes } from './models/Msgtypes';
import { TimelineService } from './services/timeline.service';
import { ZoneDataManagerService } from './services/zone-data-manager.service';
import { LocationPage } from './pages/location/location.page';
import { AuthService } from './services/auth.service';
import { Howl } from 'howler';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public isSFSconnected = false;
  public czyJestWdrodze = false;


  public isMuted = true;
  
  public bgSound: Howl;
  public sfs: SFS2X.SmartFox;
  public room: SFS2X.SFSRoom;
  public currentRoomItems = [];
  public selectedIndex = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private zoneDataManager: ZoneDataManagerService,
    public timelineService: TimelineService
  ) {
    this.initializeApp();
    this.timelineService.appPages = [{
      title: 'Kompas',
      url: '/p3',
      icon: 'navigate-circle',
      color: 'danger'
    },
    {
      title: 'Twój akwen',
      url: '/profile/me',
      icon: 'cube',
      color: 'secondary'
    },
    {
      title: 'Archipelag',
      url: '/maps',
      icon: 'map',
      color: 'success'
    },
    {
      title: 'Liquid',
      url: '/skrzynia/',
      icon: 'qr-code',
      color: 'warning'
    }];
  }

  flipMuted()
  {
    this.isMuted = !this.isMuted;
    if (this.isMuted)
    {
      // this.bgSound.pause();
    }
    else
    {
      // this.bgSound.play();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      const config = {
        useSSL: false,
        debug: false,
        zone : 'ZatokaPiratow'
      };

      /*
      this.bgSound = new Howl({
        src: ['assets/audio/KompasTrailer.mp3'],
        autoplay: true,
        loop: true,
        volume: 0.3
      });
      */
  
      // this.bgSound.play();

      
      /*
      this.sfs = new SFS2X.SmartFox(config);

      this.sfs.logger.level = SFS2X.LogLevel.DEBUG;
	    this.sfs.logger.enableConsoleOutput = true;
	    this.sfs.logger.enableEventDispatching = false;
      this.sfs.addEventListener(SFS2X.SFSEvent.CONNECTION, this.onConnection, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.LOGIN, this.onLogin, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.LOGIN_ERROR, this.onLoginError, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.CONNECTION_LOST, this.onConnectionLost, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.ROOM_JOIN_ERROR, this.onRoomJoinError, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.ROOM_JOIN, this.onRoomJoin, this);
      //this.sfs.addEventListener(SFS2X.SFSEvent.PROXIMITY_LIST_UPDATE, this.onProximityListUpdate, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.USER_VARIABLES_UPDATE, this.onUserVariablesUpdate, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.PUBLIC_MESSAGE, this.onPublicMessage, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.PRIVATE_MESSAGE, this.onPrivateMessage, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.USER_ENTER_ROOM, this.onUserEnterRoom, this);
      //this.sfs.addEventListener(SFS2X.SFSEvent.USER_EXIT_ROOM, this.onUserExitRoom, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.EXTENSION_RESPONSE, this.onExtensionResponse, this);
      this.sfs.addEventListener(SFS2X.SFSEvent.ADMIN_MESSAGE, this.onAdminMessage, this);
      //this.sfs.addEventListener(SFS2X.SFSEvent.MMOITEM_VARIABLES_UPDATE, onMMOItemVariablesUpdate, this);
      this.sfs.connect('localhost', 8080);
      //this.sfs.connect('enclavia.unicloud.pl', 433);
      //this.sfs.connect('127.0.0.1', 8080);
*/
      this.timelineService.appReference = this;
    });
  }

/*
  onUserExitRoom(event)
  {
    //console.log("User " + event.user.name + " (" + event.user.id + ") left the Room");

    let msgBar = {
      userId: event.user.name,
      text: event.user.name + " opuszcza lokację",
      type: MsgTypes.EXITS,
      payload: {}
    }


    this.timelineService.addChatMSG(msgBar);
  }
  */

 onAdminMessage(evtParams)
 {
   console.log("The administrator sent the following message: " + evtParams.message);
 }
  onUserEnterRoom(event)
  {
    console.log("User " + event.user.name + " (" + event.user.id + ") entered the Room");

    // For example code simplicity we rebuild the full userlist instead of just adding the specific item
    
    
    let msgBar = {
      userId: event.user.name,
      text: event.user.name + " wchodzi do lokacji",
      type: MsgTypes.ENTERS,
      payload: {}
    }
    this.timelineService.addChatMSG(msgBar);
  }
  onPrivateMessage(evtParams)
	{
		var sender = evtParams.sender;
    var msg = evtParams.message;
    var data = evtParams.data;

    console.log("PRIVATE", msg, sender, data);
    if(sender.name == 'Admin' && msg=='respawn')
    {
      this.respawn();
    }
  }

  respawn()
  {
    console.log("respawning");

    this.timelineService.messages = [];
    this.timelineService.isLimbo = false;
    this.timelineService.iamFighting = false;
    this.isSFSconnected = false;
    this.timelineService.messages = [];
    window.location.reload();
    // this.timelineService. = false;
    // let lobbyRoom = this.sfs.getRoomByName("THE_LOBBY");
    // this.timelineService.locationPage.showDescription();

  }

  
  

  onPublicMessage(evtParams)
	{
		var sender = evtParams.sender;
    var msg = evtParams.message;
    var data = evtParams.data;

    console.log("onPublicMessage", msg, sender, data);

    if(!sender || !sender.name || sender.name == undefined) return;
    
    
    
    let msgBar = {
      userId: sender.name,
      text: msg,
      type: MsgTypes.PUBLIC_MSG,
      payload: data,
      image: sender.getVariable('image').value
    }

//mySelf
    //if(sender._isItMe)
    //{
    //  console.log('to ja')
    //}


    this.timelineService.locationPage.showRoomers();

    

    this.timelineService.addChatMSG(msgBar);
  }

  onUserVariablesUpdate(event)
  {
    console.log("onUserVariablesUpdate ", event); 
  }

  async startWalkingToLocation(locID, dir, fromID, distance)
  {
    console.log("startWalkingToLocation ", locID, dir, ' from ', fromID, ' distance ', distance);
    

    //this.sendMessage(this.myName + " rusza w kierunku " + dir + " do lokacji: " + locID)

    this.czyJestWdrodze = true;
    

    this.currentRoomItems = [];
    //this.timelineService.messages = [];
    this.zoneDataManager.actualRoomName = "Jesteś w drodze na " + dir + " do " + locID
    

    var isSent = await this.sfs.send(new SFS2X.LeaveRoomRequest(this.sfs.lastJoinedRoom));

    console.log('isSent, startMoveFromLocation', isSent, this.timelineService.startMoveFromLocation.globalSFSName)

    await this.awaitFunction(300);

    console.log('after');
    this.sfs.send(new SFS2X.JoinRoomRequest(locID));

    if (this.sfs.lastJoinedRoom != null && locID == this.sfs.lastJoinedRoom.id)
    {
      console.log('oststni pokoj istnieje i jestem w rooms[0]');
    }

    this.czyJestWdrodze = false;

    //let odleglosc

    await this.awaitFunction(300);
    let odwrotnosc ="";
    if(dir=='N') odwrotnosc = 'nadchodzi z południa';
    else if(dir=='NE') odwrotnosc = 'nadchodzi z południowego-zachodu';
    else if(dir=='E') odwrotnosc = 'nadchodzi z zachodu';
    else if(dir=='SE') odwrotnosc = 'nadchodzi z północnego-zachodu';
    else if(dir=='S') odwrotnosc = 'nadchodzi z północy';
    else if(dir=='SW') odwrotnosc = 'nadchodzi z północnego-wschodu';
    else if(dir=='W') odwrotnosc = 'nadchodzi ze wschodu';
    else if(dir=='NW') odwrotnosc = 'nadchodzi z południowego-wschodu';

    this.sendMessage(this.timelineService.myName + " " + odwrotnosc + ".");

    
  }

  async awaitFunction (milisecs) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve();
      }, milisecs);
    });
  };
  
  sendMessage(txt)
  {
    if(!txt) return;

    console.log("sendMessage ", txt);
    this.sfs.send(new SFS2X.PublicMessageRequest(txt));

  }

  onConnectionLost(event)
  {
    console.log("Disconnection occurred; reason is: " + event.reason); 
    this.isSFSconnected = false;
    this.timelineService.messages = [];
    window.location.reload(true);
  }

  populateRoomsList()
  {
    //var rooms = this.sfs.roomManager.getRoomList();
   
 
    //console.log('rooms', rooms);
    //console.log('this.sfs.lastJoinedRoom', this.sfs.lastJoinedRoom);


    //this.timelineService.actualRoomName = "THE_LOBBY";

    //this.sfs.send(new SFS2X.JoinRoomRequest("THE_LOBBY"));
                   
  }


  onRoomJoinError(evtParams)
	{
		console.log("Room join error: " + evtParams.errorMessage + " (code: " + evtParams.errorCode + ")", true);
	}

	onRoomJoin(evtParams)
	{
    
    this.zoneDataManager.actualRoomName = evtParams.room.name;

    this.zoneDataManager.currentLocation = this.zoneDataManager.getLocationByID(this.zoneDataManager.actualRoomName)
    console.log("Room joined: " + evtParams.room.name);

    this.room = evtParams.room;
    console.log("{}: " , evtParams);
    this.currentRoomItems = [];//evtParams.room.getVariables();
    //console.log("currentRoomParams: " , this.currentRoomParams[0]);

    //this.getLocationData();

    let msgBar = {
      userId: this.timelineService.myName,
      text: "Wchodzisz do lokacji "+this.zoneDataManager.actualRoomName,
      type: MsgTypes.MOVE,
      payload: {}
    }
    
    this.timelineService.addChatMSG(msgBar);

    evtParams.room.getVariables().forEach(elementy => {
      console.log('el', elementy)

      if(elementy.name=="items")
      {
        console.log('itemz', elementy.value._dataHolder)
        let arrayy = elementy.value._dataHolder
        for (let index = 0; index < arrayy.length; index++) {
          const element = arrayy[index];
          const itemID = element.value
          console.log('---', itemID);
          this.currentRoomItems.push(itemID); 
        }
      }
    });

		// Create an array that will contain all map objects (static and dynamic, including avatars) used for sprites sorting purposes
		let mapObjects = [];

		// Switch view
		//setView("game");

		// Draw curtain
		//curtain = new createjs.Shape();
    //    stage.addChild(curtain);

		// Retrieve Room Variable containing access points coordinates
		// (see Extension comments to understand how data is organized)
		//var mapData = evtParams.room.getVariable("mapData").value;
		//var accessPoints = mapData.get("accessPoints");

		// Select a random access point among those available
		//var index = Math.floor(Math.random() * accessPoints.length / 2) * 2;
		//var accessX = accessPoints[index];
		//var accessY = accessPoints[index + 1];

		// Set starting direction to a default value
		//var dir = AVATAR_DIRECTIONS[2];

		// Create current user's avatar
		//createAvatar(sfs.mySelf, accessX, accessY, dir);

		// Arrange map objects sorting
		//arrangeMapObjects();

		// The position is saved in the User Variables, so that changing it later will trigger the avatar animation
		//setAvatarVariables(accessX, accessY, dir);

		// Declare current user's position in the MMORoom, to get the proximity list of users
		//updateServerPosition();
	}
  
  onLogin(evtParams)
  {
    console.log("Login successful!", evtParams);
    this.zoneDataManager.initData();
  }


  getLocationData()
  {
    console.log("@@@@@ getLocationData");
    
    var params = new SFS2X.SFSObject();
    params.putText("room", this.room.id.toString());
    this.sfs.send( new SFS2X.ExtensionRequest("locationDataRequest", params) );
  }

  async odpalLimbo()
  {
    console.log("odpalamy limbo");
    this.timelineService.messages = [];

    await this.awaitFunction(1000);

    let msg = {
      userId: "Zegarmistrz",
      text: "odlatujesz",
      type: MsgTypes.ADMIN,
      payload: null,
      image: "./assets/images/enclavia-logo-small.png"
    }

    let msg2 = {
      userId: "Zegarmistrz",
      text: "lewitujesz",
      type: MsgTypes.ADMIN,
      payload: null,
      image: "./assets/images/enclavia-logo-small.png"
    }

    let msg3 = {
      userId: "Zegarmistrz",
      text: "poznajesz kosmos",
      type: MsgTypes.ADMIN,
      payload: null,
      image: "./assets/images/enclavia-logo-small.png"
    }

    let msg4 = {
      userId: "Zegarmistrz",
      text: "zaczynasz lądować",
      type: MsgTypes.ADMIN,
      payload: null,
      image: "./assets/images/enclavia-logo-small.png"
    }

    let msg5 = {
      userId: "Zegarmistrz",
      text: "widzisz juz swiatlo",
      type: MsgTypes.ADMIN,
      payload: null,
      image: "./assets/images/enclavia-logo-small.png"
    }

    
    this.timelineService.addChatMSG(msg);
    await this.awaitFunction(4000);
    this.timelineService.addChatMSG(msg2);
    await this.awaitFunction(8000);
    this.timelineService.addChatMSG(msg3);
    await this.awaitFunction(12000);
    this.timelineService.addChatMSG(msg4);
    await this.awaitFunction(16000);
    this.timelineService.addChatMSG(msg5);
    
  }


  onExtensionResponse(evtParams) 
  {
      console.log("!!!!!--------Result: locationDataRequest   " + evtParams.params.getString("roomData"));
  }

  onLoginError(evtParams)
  {
    console.log("Login failure: " + evtParams.errorMessage);
  }

  onConnection(evtParams)
  {
    if (evtParams.success){
        console.log("Connected to SmartFoxServer 2X!");
        //this.myName;// = 'anon_' + Math.random().toFixed(5);

        if(localStorage.getItem("myName"))
        {
          this.timelineService.myName = localStorage.getItem("myName");
        }
        else
        {
          
          localStorage.setItem("myName", this.timelineService.myName);
        }

        this.sfs.send(new SFS2X.LoginRequest(this.timelineService.myName, "", null, "Enclavia"));
    }
    else{
      console.log("Connection failed. Is the server running at all?");
    }
  }
          

  ngOnInit() {
    console.log('App onInit', this.auth.czekAccount());    
  }
}
