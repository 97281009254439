export enum MsgTypes {

    ADMIN =  "ADMIN",
    PUBLIC_MSG = "PUBLIC_MSG",
    SELF = "SELF",
    ENTERS = "ENTERS",
    EXITS = "EXITS",
    INFO = "INFO",
    MOVE = "MOVE",
    TRAVEL="TRAVEL",
    PLAYERS="PLAYERS",
    ROZA_WIATROW="ROZA_WIATROW",
    LOCATION="LOCATION"
}